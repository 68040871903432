<template>
<div>
    <v-card>
        <v-card-title class="pa-4 info">
            <span class="title white--text">LISTE DES STATIONS  PLUVIOMETRES</span>
        </v-card-title>

        <v-card-text>

            <v-container>
                <v-row>
                    <v-col cols="12" sm="8">
                        <v-row>
                          
                            <v-col cols="12" sm="6">
                                <v-select :items="listcommune3" @change="fuseSearchcommune3" outlined hide-details item-value="id" v-model="selectcommune3" label="Commune *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="listarrondissement3" @change="fuseSearcharrondissement3" outlined hide-details item-value="id" v-model="selectarrondissement3" label="Arrondissement *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>

                                </v-select>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-select :items="listvillage3" outlined hide-details item-value="id" v-model="selectvillage3" label="Village/Ville *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>

                                </v-select>
                            </v-col>
                            
                            <v-col cols="12" sm="6">
                                <v-select :items="listdirection2" outlined hide-details item-value="id" 
                                @change="fuseSearchdirection2"
                                v-model="selectdirection2" label="List des directions *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle_ddaep}}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle_ddaep }}
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                   

                </v-row>

            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" dark @click="dialog=false">Annuler</v-btn>
            <v-btn color="success" dark @click="recherche">Recherche</v-btn>
        </v-card-actions>
    </v-card>
    <v-container fluid class="down-top-padding">
        
        <v-card>
            <v-card-text class="pa-2">
                <span style="width: 100%;text-align: center"> LISTE DES STATIONS </span>

                <v-data-table :headers="headers" :items="list" sort-by="id" class="contact-listing-app" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat>

                            <v-row>
                                <v-col cols="12" lg="6">
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Recherche...." filled background-color="transparent" hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="700">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="primary" dark class="mb-2 text-capitalize" v-show="true" v-on="on" @click="close()">
                                        Nouveau
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="pa-4 info">
                                        <span class="title white--text">Formulaire</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12"  sm="6">
                                                    <v-text-field outlined hide-details v-model="editedItem.libelle" label="Libelle de Station pluviometre *"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-textarea v-model="editedItem.description" label="Description" auto-grow outlined rows="1" row-height="15"></v-textarea>
                                                </v-col>
                                                
                                                
                                                <v-col cols="12">
                                                    <v-select :items="listdirection" outlined hide-details item-value="id" v-model="selectdirection" label="List des directions *">
                                                        <template slot="selection" slot-scope="data">
                                                            {{ data.item.libelle_ddaep }}
                                                        </template>
                                                        <template slot="item" slot-scope="data">
                                                            {{ data.item.libelle_ddaep }}
                                                        </template>
                                                    </v-select>
                                                </v-col>

                                                <v-col cols="12">
                                                    <v-select :items="listcommune" @change="fuseSearchcommune" outlined hide-details item-value="id" v-model="selectcommune" label="Commune *">
                                                        <template slot="selection" slot-scope="data">
                                                            {{ data.item.libelle }}
                                                        </template>
                                                        <template slot="item" slot-scope="data">
                                                            {{ data.item.libelle }}
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12"  sm="6">
                                                    <v-select :items="listarrondissement" @change="fuseSearcharrondissement" outlined hide-details item-value="id" v-model="selectarrondissement" label="Arrondissement *">
                                                        <template slot="selection" slot-scope="data">
                                                            {{ data.item.libelle }}
                                                        </template>
                                                        <template slot="item" slot-scope="data">
                                                            {{ data.item.libelle }}
                                                        </template>

                                                    </v-select>
                                                </v-col>

                                                <v-col cols="12"  sm="6">
                                                    <v-select :items="listvillage" outlined hide-details item-value="id" v-model="selectvillage" label="Village/Ville *">
                                                        <template slot="selection" slot-scope="data">
                                                            {{ data.item.libelle }}
                                                        </template>
                                                        <template slot="item" slot-scope="data">
                                                            {{ data.item.libelle }}
                                                        </template>

                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12"  sm="6">
                                                    <v-text-field outlined hide-details v-model="editedItem.longitude" label="Longitude *"></v-text-field>
                                                </v-col>
                                                <v-col cols="12"  sm="6">
                                                    <v-text-field outlined hide-details v-model="editedItem.latitude" label="Latitude *"></v-text-field>

                                                </v-col>

                                            </v-row>

                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" dark @click="dialog=false">Cancel</v-btn>
                                        <v-btn color="success" dark @click="Save">Enregistrer</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                        </v-toolbar>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex">

                            <v-icon large class="mb-2 mr-2 info--text" @click="getitem(item)">mdi-pencil</v-icon>
                            <v-icon large class="mb-2 mr-2 error--text" @click="deletItem(item)">mdi-delete</v-icon>
                        </div>
                    </template>

                    <template v-slot:no-data>
                        
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <v-btn v-if="item.status==1" color="success" dark class="mb-2 text-capitalize" @click="getitem_status(item)">
                        Actif</v-btn>
                        <v-btn v-else color="error" dark class="mb-2 text-capitalize" @click="getitem_status(item)">
                        Inactif</v-btn>
                    </template>

                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</div>
</template>

<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "ProjectList",
    data: () => ({
        dialog: false,
        search: "",
        headers: [{
                text: "Actions",
                align: "start",
                sortable: false,
                value: "actions",
                divider: true,
                width: '4%'

            },
            {
                text: "Id",
                align: "start",
                sortable: false,
                value: "id",
                divider: true,
                width: '5%'
            },
            {
                text: "Libelle du pluviometre",
                align: "start",
                sortable: false,
                value: "libelle",
                divider: true,
                width: '150px'
            },
            {
                text: "Description",
                align: "start",
                sortable: false,
                value: "description",
                divider: true,
                width: '150px'
            },
            {
                text: "Direction assignée",
                align: "start",
                sortable: false,
                value: "direction.libelle_ddaep",
                divider: true,
                width: '100px'
            },
            {
                text: "Le lieu",
                align: "start",
                sortable: false,
                value: "village.libelle",
                divider: true,
                width: '80px'
            },
            {
                text: "L'arrondissement",
                align: "start",
                sortable: false,
                value: "village.arrondissement.libelle",
                divider: true,
                width: '80px'
            },
            {
                text: "Commune",
                align: "start",
                sortable: false,
                value: "village.arrondissement.commune.libelle",
                divider: true,
                width: '80px'
            },

            {
                text: "Longitude",
                align: "start",
                sortable: false,
                value: "longitude",
                divider: true,
                width: '80px'
            },
            {
                text: "Latitude",
                align: "start",
                sortable: false,
                value: "latitude",
                divider: true,
                width: '80px'
            },

            {
                text: "status",
                value: "status",
                divider: true,
                width: '50px'
            },

        ],
        editedItem: {
            id: '',
            libelle: '',
            description: '',
            latitude: '',
            longitude: '',
            id_villages: '',
            id_directions: '',
        },

        list: [],
        listdirection: [],
        listdirection2: [],

        listcommune: [],
        listarrondissement: [],
        listvillage: [],

        listarrondissement2: [],
        listvillage2: [],


        listcommune3: [],
        listarrondissement3: [],
        listvillage3: [],

        etat: false,
        selectcommune: '',
        selectarrondissement: '',
        selectvillage: '',

        selectdepartement3: '',
        selectcommune3: '',
        selectarrondissement3: '',
        selectvillage3: '',
      

        selectdirection: '',
        selectdirection2: '',

    }),

    watch: {

    },

    created() {
        //this.readAll_geographie();
        //this.readAll_Survey();
        this.readAll();
        this.readAll_globale_localisation();
        // this.readAll_Export_Excel();
    },
    methods: {

        readAll: async function () {
            let fd = new FormData();
            fd.append('id_direction', this.selectdirection2);
            fd.append('id_village', this.selectvillage3);
            fd.append('id_arrondissement', this.selectarrondissement3);
            fd.append('id_commune', this.selectcommune3);
            const data = await api.createUpdatedata('admin-backoffice/all-pluviometre', fd);
            this.list = data.succes;

        },

        recherche(){
            this.readAll();
        },

        readAll_globale_localisation: async function () {
           
            const data = await api.readAll('admin-backoffice/globale-localisation');
            this.listcommune = data.commune;
            this.listcommune3 = data.commune;
            this.listarrondissement2 = data.arrondissement;
            this.listvillage2 = data.village;
            
            this.listdirection = data.ddaep;
            this.listdirection2 = data.ddaep;

        },

        Save: async function () {

            let fd = new FormData();
            fd.append('libelle', this.editedItem.libelle);
            fd.append('description', this.editedItem.description);
            fd.append('latitude', this.editedItem.latitude);
            fd.append('longitude', this.editedItem.longitude);
            fd.append('id_villages', this.selectvillage);
            fd.append('id_directions', this.selectdirection);
            if (this.etat == false) {
                const data = await api.createUpdatedata('admin-backoffice/add-pluviometre', fd);
                if (data.success.status == true) {
                    this.showAlert('Enregistrement effectué avec succès', 'success')
                    this.clearitem();

                } else if (data.success.status == false) {
                    this.showAlert(data.success.message, 'error')
                }

            } else if(this.etat == true) {
                const data = await api.createUpdatedata('admin-backoffice/update-pluviometre/' + this.editedItem.id, fd);
                if (data.success.status == true) {
                    this.showAlert('Modification effectuée avec succès', 'success')
                    this.clearitem();
                    this.dialog = false;

                } else if (data.success.status == false) {
                    this.showAlert('Modification non effectuée', 'error')
                }

            }
            this.clearitem();

            this.readAll();

        },

        deletItem: async function (item) {

            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    const data = await api.readAll('admin-backoffice/delete-pluviometre/' + item.id);
                    if (data.status == true) {
                        this.showAlert('Suppression effectuée avec succès', 'success')
                        this.clearitem();
                        this.dialog = false;

                    } else if (data.status == false) {
                        this.showAlert('Suppression non effectuée', 'error')
                    }
                    this.readAll();

                }
            })

        },

        clearitem() {
            this.selectdirection='0';
            this.selectarrondissement='0';
            this.selectcommune='0';
            this.selectvillage='0';
            this.editedItem.libelle = '';
            this.editedItem.description = '';
            this.editedItem.latitude = '';
            this.editedItem.longitude = '';
            this.editedItem.id_villages = '';
            this.editedItem.id_directions = '';
            this.etat = false;
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },

        getitem_status(item){
            this.$swal.fire({
                title: 'Vous etes sur?',
                text: "Voullez vous vraiment modifier l'etat de cet enregistrement?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmer!'
            }).then(async (result) => {
                if (result.isConfirmed) {

                   // let fd = new FormData();
                    const data = await api.readAll('admin-backoffice/pluviometre-status/' + item.id);
                    if (data.status == true) {
                        this.showAlert('Modification effectuée avec succès', 'success')
                        //this.clearitem();
                        //this.dialog = false;

                    } else if (data.status == false) {
                        this.showAlert('Modification non effectuée', 'error')
                    }
                    this.readAll();

                }
            })
        },

        getitem(item) {
            this.etat = true;
            
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
            this.selectdirection = item.id_directions;
        },

        fetchData() {
            this.readAll();
        },

        fuseSearchcommune: function () {
            this.listarrondissement= this.listarrondissement2.filter(this.parcourre_arrondissement);
            this.listvillage=[];
           
        },

        fuseSearcharrondissement: function () {
            this.listvillage= this.listvillage2.filter(this.parcourre_village);
        },

        fuseSearchcommune3: function () {
            this.listarrondissement3= this.listarrondissement2.filter(this.parcourre_arrondissement3);
            this.listvillage3=[];
           
        },

        fuseSearcharrondissement3: function () {
            this.listvillage3= this.listvillage2.filter(this.parcourre_village3);
        },

        parcourre_arrondissement: function (obj) {
            if (obj.id_communes == this.selectcommune) {
                return true;
            } else {
                return false;
            }
        },

        parcourre_village: function (obj) {
            if (obj.id_arrondissements == this.selectarrondissement) {
                return true;
            } else {
                return false;
            }
        },

        parcourre_arrondissement3: function (obj) {
            if (obj.id_communes == this.selectcommune3) {
                return true;
            } else {
                return false;
            }
        },

        parcourre_village3: function (obj) {
            if (obj.id_arrondissements == this.selectarrondissement3) {
                return true;
            } else {
                return false;
            }
        },

    },
};
</script>
